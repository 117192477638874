@import 'src/global/mixins.scss';

.autoCompleteResultCategory {
  display: flex;
  flex-direction: column;
  margin: calc(3 * var(--global-space-half)) 0;

  .heading {
    display: flex;
    align-items: center;
    gap: var(--global-space-single);

    .info {
      font-size: 10px;
      color: var(--global-color-dark-gray);
    }
  }

  .title,
  .option {
    padding:
      calc(1.5 * var(--global-space-half))
      0
      calc(1.5 * var(--global-space-half))
      calc(13 * var(--global-space-half));
  }

  .option {
    display: flex;
    flex-direction: row;
    gap: var(--global-space-half);

    &:hover,
    &.highlightedOption {
      background-color: var(--global-color-gray-4);
    }
  }
}
