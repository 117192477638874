@import 'src/global/mixins.scss';

.titleWithInfo {
  @include flex-start;

  .infoButton {
    color: var(--global-color-gray-2);
    align-self: flex-end;
    margin: 0 var(--global-space-single);
  }
}

.guideLink {
  @include flex-horizontal;
  color: var(--global-color-blue);
  font-size: 12px;
  padding: var(--global-space-single);

  .icon {
    margin-right: var(--global-space-half);
    width: 12px;
    height: 12px;
    color: var(--global-color-blue);
  }
}

// :root is needed to make the style more specific
// otherwise it gets overridden by global modal styles

:root .textLink {
  color: var(--global-color-blue);
  font-size: 14px;
}
