@import 'src/global/mixins.scss';

@mixin visible-scrollbar {
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 0;
  }

  &::-webkit-scrollbar:vertical {
    -webkit-appearance: none;
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: var(--global-color-dark-gray);
  }
}

.switchTenantModalContent {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 70vh;
  gap: var(--global-space-single);

  .tenantsList {
    @include rounded;
    @include visible-scrollbar;
    padding: var(--global-space-single) 0;
    border: 1px solid var(--global-color-gray-4);
    flex-grow: 1;

    .tenantButton {
      @include flex-horizontal;
      gap: var(--global-space-half);
      padding-left: var(--global-space-double);
      width: 100%;

      .tenantId {
        opacity: 0.5;
      }
    }
  }
}
