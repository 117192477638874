@import 'src/global/mixins.scss';

.masterFiltersPanelHeader {
  @include flex-horizontal;
  flex-direction: column;
  gap: var(--global-space-single);
  margin: var(--global-space-triple) var(--global-space-double) var(--global-space-double);
  align-items: start;

  .firstRow {
    @include flex-horizontal;
    width: 100%;
    justify-content: space-between;

    h4 {
      font-weight: 500;
      font-size: 18px;
    }

    .actions {
      display: flex;
      align-items: stretch;
      gap: var(--global-space-single);

      button {
        @include flex-horizontal;
        @include rounded;
        border: 1px solid var(--global-color-gray-4);
        gap: var(--global-space-single);
        padding: var(--global-space-single);
        color: var(--global-color-dark-gray);
        font-size: 12px;
        height: var(--global-button-height);
      }
    }
  }
}
