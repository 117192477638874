@import 'src/global/mixins.scss';

.slider {
  position: relative;
  width: 100%;
  height: 5px;
  margin: auto;

  $slider-height: 5px;

  &::after {
    position: absolute;
    content: '';
    height: $slider-height;
    border-radius: 2px;
    width: 100%;
    background: var(--global-color-dark-gray);
    z-index: 1;
    top: 2px;
  }

  &.colored::after {
    background:
      linear-gradient(
        to right,
        var(--slider-color-1) 0%,
        var(--slider-color-2) 20%,
        var(--slider-color-3) 60%,
        var(--slider-color-4) 80%,
        var(--slider-color-5) 100%
      );
  }

  input[type='range'] {
    position: absolute;
    height: $slider-height;
    border-radius: 2px;
    width: 100%;
    top: 0;
    outline: none;
    -webkit-appearance: none;
    cursor: pointer;
    z-index: 2;
    margin-left: 0;
    margin-right: 0;
  }
  @mixin slider-thumb {
    border-radius: 20px;
    background: var(--slider-thumb-color);
    cursor: pointer;
    width: 3px;
    height: 3px;
    margin-top: -1px;
    transform: scale(4);
    @include transition(background);
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    @include slider-thumb;
  }

  input[type='range']::-moz-range-thumb {
    @include slider-thumb;
  }

  .tickMarksWrapper {
    @include flex-horizontal;
    margin-top: 3px;
    justify-content: space-between;

    .tickMark {
      height: 4px;
      width: 1px;
      background-color: var(--global-color-gray-4);

      &:first-of-type {
        border-left: 0;
      }
    }
  }
}

.legendLabels {
  @include flex-horizontal;
  align-self: stretch;
  justify-content: space-between;

  .label {
    font-size: 12px;
    font-weight: 500;
    color: var(--global-color-dark-gray);
    margin-top: var(--global-space-single);
  }
}
