@import 'src/global/mixins.scss';
@import 'src/common-ui-components/MultiImages/style.module.scss';

$small-image-dimension: 15px;
$medium-image-dimension: 22px;
$big-image-dimension: 32px;
$long-image: 8px;

.nodeImageWrapper {
  @include flex-center;

  &.small {
    width: $small-image-dimension;
    height: $small-image-dimension;
  }

  &.medium {
    width: $medium-image-dimension;
    height: $medium-image-dimension;
  }

  &.big {
    width: $big-image-dimension;
    height: $big-image-dimension;
  }

  .button {
    @include transition(background-color);
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: var(--department-color);

    &.round {
      @include round-image;
    }

    &.square {
      @include square-image;
    }

    &.noImage {
      &::after {
        opacity: 1;
      }

      &:hover::after {
        opacity: 0.3;
        background-color: white;
      }
    }

    .imageContainer {
      height: 100%;
      @include flex-center;

      .image {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border: var(--department-color);
        background-color: white;
        object-fit: contain;

        &.largeImage {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
        }

        &.roundImage {
          @include round-image;
        }

        &.squareImage {
          @include square-image;
        }
      }

      .transparentOverlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        opacity: 0.4;
      }
    }
  }
}
