@import 'src/global/mixins.scss';

.connectorSuccessPage {
  @include flex-center(column);
  min-height: 100vh;

  h1 {
    font-size: 24px;
    font-weight: 500;
    margin-top: var(--global-space-triple);
    margin-bottom: var(--global-space-double);
  }

  h2 {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    max-width: 500px;
    text-align: center;
  }
}
