@import 'src/global/mixins.scss';

$badge-padding: var(--global-space-half) var(--global-space-single);

.badge {
  position: relative;
  align-items: center;
  box-sizing: border-box;
  font-weight: 400;
  background-color: var(--global-color-gray-4);
  color: var(--global-color-dark-gray);
  display: inline-block;
  word-wrap: break-word;
  padding: $badge-padding;
  text-align: center;
  font-size: 12px;
  border-radius: 12px;

  &.small {
    font-size: 10px;
    font-weight: 500;
  }

  &.medium {
    font-size: 12px;
  }

  &.big { // Not yet used.
    font-size: 12px;
  }
}
