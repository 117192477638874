@import 'src/global/mixins.scss';

.toggleBtn {
  @include flex-center;
  gap: var(--global-space-single);
  border: 1px solid var(--global-color-gray-4);
  color: var(--global-color-dark-gray);
  height: var(--global-button-height);
  font-size: 12px;

  &.darkBorder {
    border: 1px solid var(--global-color-border-gray);
  }

  svg {
    width: 16px;
  }
}

.title {
  color: var(--global-color-dark-blue);
  padding: 0 var(--global-space-double);
  padding-top: var(--global-space-single);
  font-size: 12px;
}
