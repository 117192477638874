$padding-y: 2px;
$padding-x: 6px;
$border-radius: 10px;

.betaLabel {
  font-size: 10px;
  font-weight: 700;
  color: var(--global-color-blue);
  padding: $padding-y $padding-x;
  border: 1px solid var(--global-color-blue);
  border-radius: $border-radius;
  transition:
    color var(--global-transition-duration) ease,
    border var(--global-transition-duration) ease;

  &.disabled {
    color: var(--global-color-gray-7);
    border: 1px solid var(--global-color-gray-7);
  }
}
