@import 'src/global/mixins.scss';

.button {
  display: inline-flex;
  background-color: white;

  .icon {
    color: var(--global-color-es-gray-dark);
    width: 16px;
    height: 16px;
    @include transition(fill);

    &.upsideDown {
      transform: rotate(180deg);
    }

    &.selected {
      fill: var(--global-color-yellow);
    }
  }

  &.disabled {
    cursor: default;

    &.gray .icon {
      color: var(--global-color-gray-3);
      fill: var(--global-color-gray-4);
    }
  }

  &:not(.disabled):hover .icon {
    fill: var(--global-color-yellow);
  }
}
