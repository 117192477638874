$content-size: 10px;

.interactionsStatus {
  display: flex;
  align-items: center;
  color: var(--global-color-dark-gray);
  font-family: Poppins, sans-serif;
  font-size: $content-size;
  font-weight: 500;
  letter-spacing: 1.2px;
  min-height: $content-size;
  max-height: $content-size;
}
