@import 'src/global/mixins.scss';

@mixin enter-icon {
  margin-left: var(--global-space-single);
  max-width: 0;
  opacity: 0;
  padding: 0;
  transition:
    opacity var(--global-transition-duration) ease,
    max-width var(--global-transition-duration) ease;
}
@mixin selected-enter-icon {
  max-width: 30px;
  opacity: 1;
  padding: var(--global-space-half);
}
