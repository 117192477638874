@import 'src/global/mixins.scss';

.expandableWrapper {
  @include flex-start;
  min-height: 22px;
  min-width: 240px;

  .expandable {
    height: 100%;
    overflow: hidden;
    @include flex-horizontal;
    @include transition(max-height);
    flex-wrap: wrap;
    gap: var(--global-space-single);
  }

  .ellipsisToggle {
    @include flex-horizontal;
    width: 50px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.5;
    text-decoration: underline;
    margin-left: var(--global-space-single);
    height: var(--collapsed-height);
    flex-shrink: 0;
    @include transition(color);
    color: var(--global-color-blue);

    &:hover {
      color: hsl(var(--global-color-blue-h) var(--global-color-blue-s) calc(var(--global-color-blue-l) * 1.2));
    }
  }
}
