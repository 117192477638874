@import 'src/global/mixins.scss';

.cardContainer {
  width: 100%;
  height: 100%;
  @include rounded-large;
  background-color: white;
  padding: var(--global-space-triple) calc(3 * var(--global-space-half));
  padding-right: calc(5 * var(--global-space-single));
}
