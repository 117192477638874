@import 'src/global/mixins.scss';

.container {
  @include flex-horizontal;
  margin-top: var(--global-space-single);
  height: 100%;

  .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--global-color-dark-blue);
  }

  .buttons {
    display: flex;
    margin-left: auto;
    gap: var(--global-space-half);

    .closeButton {
      display: flex;
      padding: var(--global-space-single);
      border: 1px solid var(--global-color-gray-4);

      .closeIcon {
        color: var(--global-color-dark-gray);
      }
    }
  }
}
