@import 'src/global/mixins.scss';
/* stylelint-disable */

*,
*::before,
*::after {
  box-sizing: border-box;
}

.ant-pagination {
  @include flex-horizontal;
  margin-bottom: 0;
}

.ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-item-container .ant-pagination-item-link {
  color: var(--global-color-dark-gray);
}

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  @include rounded;
  overflow: hidden;
  line-height: 24px;

  :hover {
    background-color: var(--global-color-gray-4);
  }

  a,
  .ant-pagination-item-link,
  .ant-pagination-item-link-icon {
    color: var(--global-color-dark-gray);
  }

  &.ant-pagination-item-active {
    border: 0;
    background-color: var(--global-color-dark-gray);
    font-weight: 400;
    a {
      color: white;
    }
  }
}


.paginationButton {
  @include flex-center;
  gap: var(--global-space-single);
  margin: 0 20px;
  height: 100%;

  .text {
    color: var(--global-color-dark-gray);
    align-self: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.3px;
  }

  .caret {
    color: var(--global-color-dark-gray);

    &.pointingRight {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.2;
  }
}
