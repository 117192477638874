@import 'src/global/mixins.scss';

.screenContentCard {
  @include content-card;

  &.fullScreen {
    min-height: 100%;
  }
}

.title {
  color: var(--global-color-dark-blue);
  font-size: 18px;
  font-weight: 500;
}
