@import 'src/global/mixins.scss';

$hint-background: rgb(216 241 255);

.hintMessage {
  @include flex-start;
  align-items: center;
  position: sticky;
  bottom: 20px;

  .content {
    @include flex-start;
    align-items: center;
    position: absolute;
    background-color: $hint-background;
    width: 100%;
    padding: calc(3 * var(--global-space-half)) calc(8 * var(--global-space-half));
    gap: var(--global-space-single);

    .text {
      font-size: 14px;
    }

    .icon {
      height: 16px;
      width: 16px;
    }
  }
}
