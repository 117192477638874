.loadingModal {
  text-align: center;
  background-color: white;
  padding: 30px 40px;
  border-radius: 20px;
  box-shadow: 0 0 35px 0 rgb(0 0 0 / 12%);
  margin-top: -20vh;
  color: var(--global-color-dark-gray);
  z-index: var(--global-z-index-loading-modal);

  &.fullScreen {
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
  }
}
