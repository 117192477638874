@import 'src/global/mixins.scss';

.textLoader {
  @include flex-stretch;
  gap: var(--global-space-single);
  max-width: fit-content;

  .message {
    font-size: 14px;

    &.enterpriseSearch {
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
