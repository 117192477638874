@import 'src/global/mixins.scss';

.sourceBadge {
  padding: var(--global-space-half) var(--global-space-single);
  border: 1px solid var(--global-color-gray-4);

  .sourceLink {
    @include flex-center;
    gap: var(--global-space-half);
    max-width: var(--source-badge-max-width);
  }

  .sourceTitle {
    @include ellipsis;
    color: var(--global-color-es-gray-dark);
    font-size: 12px;
    font-weight: 400;
    flex-shrink: 1;
  }

  .externalLinkIcon {
    width: 0;
    opacity: 0;
  }

  &:hover {
    background-color: var(--global-color-light-blue);

    .externalLinkIcon {
      width: 10px;
      opacity: 1;
      color: var(--global-color-dark-gray);
      flex-shrink: 0;
    }
  }
}
