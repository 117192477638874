@import 'src/global/mixins.scss';

.toast {
  @include rounded;
  max-width: calc(100vw - 100px);
  padding: var(--global-space-double);

  .ant-notification-notice-message {
    color: var(--global-color-dark-blue);
    margin-bottom: 0;
    padding-right: var(--global-space-quadruple);
  }

  .ant-notification-notice-close {
    top: 20px;
    cursor: pointer;
  }
}
