@import 'src/global/mixins.scss';

.itemListing {
  @include flex-horizontal;
  margin-top: var(--global-space-single);
  margin-left: var(--global-space-double);
  width: 100%;
  color: var(--global-color-dark-blue);

  &:disabled {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  div {
    @include flex-horizontal;
  }

  .labelContainer {
    font-size: 12px;
    padding-left: var(--global-space-single);
    padding-right: var(--global-space-half);
    white-space: nowrap;
    overflow: hidden;

    .label {
      @include ellipsis;
    }

    &.inactive {
      color: var(--global-color-gray);
    }

    .itemImage,
    .channelTypeImage {
      margin-right: var(--global-space-half);
    }

    .itemSize {
      margin-left: 0.8ch;
      font-size: 10px;
    }
  }
}

.tooltip {
  display: flex;
  max-width: 200px;
}

.notAllowed {
  cursor: not-allowed;
}
