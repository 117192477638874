@import 'src/global/mixins.scss';

$btn-color: var(--global-color-dark-blue);

.button {
  @include flex-horizontal;
  @include transition(background-color);
  border: 2px solid $btn-color;
  padding: 6px 10px;
  border-radius: var(--login-btns-border-radius);

  span {
    color: $btn-color;
    font-size: 18px;
    flex-grow: 1;
  }

  &:hover {
    background-color: $btn-color;

    span {
      color: white;
    }
  }

  .link {
    @include flex-horizontal;
  }

  .iconWrapper {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 40px;
    background-color: white;
    color: $btn-color;
    box-sizing: border-box;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
