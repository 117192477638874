$dot-size: 4px;
$margin: 8px;

.loadingDots {
  position: relative;
  width: $dot-size;
  height: $dot-size;
  border-radius: 5px;
  background-color: var(--global-color-blue);
  color: var(--global-color-blue);
  animation: loading-dots 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 $margin;
}

.loadingDots::before,
.loadingDots::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.loadingDots::before {
  left: calc($margin * -1);
  width: $dot-size;
  height: $dot-size;
  border-radius: 5px;
  background-color: var(--global-color-blue);
  color: var(--global-color-blue);
  animation: loading-dots 1s infinite alternate;
  animation-delay: 0s;
}

.loadingDots::after {
  left: $margin;
  width: $dot-size;
  height: $dot-size;
  border-radius: 5px;
  background-color: var(--global-color-blue);
  color: var(--global-color-blue);
  animation: loading-dots 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes loading-dots {
  0% {
    background-color: var(--global-color-blue);
  }

  100% {
    background-color: hsl(var(--global-color-blue-h) var(--global-color-blue-s) var(--global-color-blue-l) / 30%);
  }
}
