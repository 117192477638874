@import 'src/global/mixins.scss';

.getStartedHeader {
  align-self: flex-end;
  color: var(--global-color-dark-blue);
  @include flex-horizontal;

  .getStartedBtn {
    margin-left: var(--global-space-single);
    border: 1px solid var(--global-color-gray-4);

    a {
      padding: var(--global-space-single) var(--global-space-double);
      display: inline-flex;
      color: var(--global-color-dark-gray);
    }
  }
}
