@import 'src/global/mixins.scss';

.interactionTableEmptyDataMessage {
  @include flex-center(column);
  padding: var(--global-space-double);
  gap: var(--global-space-single);
  overflow: hidden;

  .icon {
    height: 26px;
    width: 26px;
    color: var(--global-color-blue);
    stroke-width: 2.5px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--global-color-dark-blue);
  }

  .subtitle {
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--global-color-dark-gray);
  }

  .button {
    display: flex;
    gap: var(--global-space-half);

    &.disabled {
      background-color: var(--global-color-gray-3);
      color: white;
    }
  }
}
