@import 'src/global/mixins.scss';

$btn-size: 24px;

.iconButton {
  @include flex-center;
  width: $btn-size;
  height: $btn-size;
  padding: var(--global-space-half);
  color: var(--global-color-dark-gray);
  background-color: var(--global-color-gray-5);
  border: 1px solid var(--global-color-gray-4);

  &:active {
    background-color: white;
  }

  &:hover:not(.notClickable) {
    background-color: var(--global-color-gray-4);
  }

  &.notClickable {
    cursor: default;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}
