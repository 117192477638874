@import 'src/global/mixins.scss';
@import 'src/common-ui-components/MultiImages/style.module.scss';

.button {
  position: relative;
  height: 100%;
  width: 100%;
  @include flex-horizontal;
  @include round-image;
  background-color: var(--global-color-gray-4);
  color: var(--global-color-dark-gray);
  padding: 2px 6px;
  font-size: 10px;
  box-sizing: border-box;
  user-select: none;
  justify-content: center;

  &.large {
    font-size: 14px;
    letter-spacing: 1px;
  }

  &.square {
    @include square-image;
  }

  & > div {
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    opacity: 0;
    @include transition(opacity);
  }

  &:hover::before {
    display: block;
    opacity: 0.3;
    -webkit-backface-visibility: hidden;
  }
}

.darkColors {
  background-color: var(--global-color-dark-gray);
  color: var(--global-color-gray-4);
}
