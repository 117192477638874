@import 'src/global/mixins.scss';

.searchIcon {
  position: absolute;
  top: 50%;
  left: var(--global-space-single);
  transform: translateY(-50%);
  color: var(--global-color-dark-gray);

  &.focused {
    color: var(--global-color-dark-blue);
  }
}
