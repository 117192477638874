@import 'src/global/mixins.scss';

.titleWithTooltipContainer {
  display: flex;
  align-items: center;
  gap: var(--global-space-half);

  .title {
    font-size: 14px;
    font-weight: 400;

    &.bold {
      font-weight: 500;
    }
  }

  .infoButton {
    display: flex;

    .icon {
      height: 14px;
      color: var(--global-color-dark-gray);
    }
  }
}
