@import 'src/global/mixins.scss';

.settingsButton {
  @include flex-center;
  color: var(--global-color-dark-gray);
  width: var(--global-button-height);
  height: var(--global-button-height);
  border: 1px solid var(--global-color-gray-4);

  svg {
    width: 14px;
    height: 14px;
  }
}
