@import 'src/global/mixins.scss';

.dateRangeFilterDropdown {
  display: flex;
  width: 500px;
  padding: 0;

  .presetTimeRanges {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex-grow: 1;

    .title {
      height: 55.3px;
      background-color: var(--global-color-gray-5);
    }

    .buttonsWrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: var(--global-space-double);
      border-right: 1px solid var(--global-color-gray-4);

      button {
        margin: var(--global-space-half) 0;
        padding: var(--global-space-single);
        border: 1px solid var(--global-color-gray-4);
        @include rounded;
        font-size: 12px;

        &.selected {
          background-color: var(--global-color-gray-4);
        }
      }
    }
  }
}
