@import 'src/global/mixins.scss';

.tooltip {
  @include shadowed;
  @include rounded;
  display: flex;
  flex-direction: column;
  padding: var(--global-space-double) var(--global-space-double);
  font-size: 12px;
  background-color: white;
  pointer-events: none;
  color: var(--global-color-dark-blue);
  border: 1px solid var(--global-color-gray-4);
  gap: var(--global-space-single);

  .row {
    @include flex-horizontal;
    justify-content: space-between;
    gap: var(--global-space-quadruple);

    &.hiddenRowsMsg {
      margin-top: var(--global-space-single);
      opacity: 0.6;
    }

    .label,
    .value {
      white-space: nowrap;
      opacity: 0.5;

      &.hovered {
        font-weight: 600;
        opacity: 1;
      }
    }

    .left {
      @include flex-horizontal;
      text-align: left;
      gap: var(--global-space-single);
    }
  }

  .title {
    white-space: nowrap;
    font-weight: 500;
    color: var(--global-color-dark-gray);
    margin-bottom: var(--global-space-half);
  }
}

.fixedTooltip {
  position: fixed;
  bottom: var(--global-space-single);
  right: 0;
  transform: translateX(50%);
}
