@import 'src/global/mixins.scss';

@keyframes cursor-blink {
  0%,
  25%,
  100% {
    opacity: 0;
  }

  40%,
  80% {
    opacity: 1;
  }
}

.typeWriter {
  line-height: 1.5;
  font-size: 14px;
  white-space: pre-line;
}

.animatedCursor {
  display: inline-block;
}

.animatedCursor::after {
  content: '';
  width: 2px;
  height: 1em;
  background: var(--global-color-dark-blue);
  display: inline-block;
  animation: cursor-blink 1.2s infinite;
  @include transition(opacity);
}
