

.info-modal {
    width: 390px;
    height: 340px;
    display: flex;
    flex-direction: column;

    .exit-button {
        top: 0;
        margin-top: -10px;
        right: 0;
        margin-right: -10px;
        position: absolute;
        height: 26px;
        width: 26px;
        border-radius: 11px;
        box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.19);
        border: solid 1px var(--global-color-gray-4);
        background-color: white;
        transition: background-color var(--global-transition-duration) ease;

        img {
            width: 11px;
            height: 11px;
        }
        &:hover {
            background-color: var(--global-color-gray-4)
        }
    }

    .info-modal-header {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: 500;
        color: var(--global-color-dark-blue);
        letter-spacing: 0.3px;
        margin-bottom: var(--global-space-double);

        img {
            padding-right: 6px;
        }
    }

    .info-modal-body {
        flex: 1;
        overflow-y: auto;
        color: var(--global-color-dark-gray);
        p {
            font-size: 12px;
            margin-block-end: unset;
            margin-block-start: var(--global-space-double);
        }
    }

    .info-modal-footer {
        display: flex;
        flex-direction: column;
        gap: 5px;
        color: var(--global-color-dark-gray);
        font-size: 12px;
        padding-top: var(--global-space-single);
        a {
            color: var(--global-color-blue);
        }
    }
}
