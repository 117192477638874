@import 'src/global/mixins.scss';

.checkboxesWrapper {
  @include flex-stretch;
  flex-direction: column;
  padding: 0 var(--global-space-single);

  .popoverCheckbox {
    @include rounded;
    padding: var(--global-space-single);
    color: var(--global-color-dark-blue);

    &:hover {
      background-color: var(--global-color-gray-4);
    }
  }
}
