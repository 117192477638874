.colored-circle {
  position: relative;
  border-radius: 50%;
  background-color: var(--iconColor);

  &.gradient {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-image: radial-gradient(ellipse at top left, rgba(black, 0) 40%, rgba(black, 0.4));
    }
  }
}
