@import 'src/global/mixins.scss';

$card-height: 171px;

.card {
  @include rounded-card;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid hsl(var(--global-color-blue-h) var(--global-color-blue-s) var(--global-color-blue-l) / 40%);
  width: 100%;
  min-height: $card-height;
  padding: var(--global-space-triple) var(--global-space-double) var(--global-space-double) var(--global-space-triple);
  box-sizing: border-box;
  @include transition(border);

  &.enabled {
    border: 1px solid var(--global-color-blue);
  }

  .header {
    @include flex-stretch;
    gap: var(--global-space-triple);
    @include transition(opacity);
    opacity: 0.4;

    &.enabled {
      opacity: 1;
    }

    .icon {
      @include flex-center;
      flex: 0 0 117px;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: var(--global-space-double);

      .title {
        font-size: 16px;
        color: var(--global-color-dark-blue);
        font-weight: 500;
        line-height: 1.25;
      }

      .paragraph {
        font-size: 14px;
        color: var(--global-color-dark-gray);
        line-height: 1.42;
        margin: 0;
      }
    }
  }

  .toggleWrapper {
    margin-left: auto;
    margin-top: auto;
  }
}
