@import 'src/global/mixins.scss';

.badgeContainer {
  @include flex-center;
  @include rounded;
  position: relative;
  padding: var(--global-space-half) var(--global-space-single);
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  word-break: break-word;
}

.badgeButton {
  &:hover {
    opacity: 0.8;
  }
}
