@import 'src/global/mixins.scss';

$card-width: 280px;
$header-height: 48px;

.atomHighlightCard {
  @include container(var(--global-space-half));
  height: 100%;

  .highlightHeader {
    display: flex;
    justify-content: space-between;

    .atomHighlightType {
      width: fit-content;
      border-radius: 100px 180px 0 100px;
      padding:
        var(--global-space-half)
        var(--global-space-double)
        var(--global-space-half)
        calc(3 * var(--global-space-half));
      background: white;
      color: var(--global-color-dark-gray);
      font-size: 10px;
      font-weight: 600;
    }

    &.skeleton {
      .atomHighlightType {
        width: 100px;
        height: 20px;
      }
    }
  }
}
