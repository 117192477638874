@import 'src/global/mixins.scss';

$popover-padding: 12px;
$results-max-height: 353px;

.searchableHierarchicalResultsContainer {
  width: 300px;
  background-color: white;
  max-height: $results-max-height;
  overflow: auto;
  @include transition(max-height);
  display: flex;
  flex-direction: column;

  .searchInputWrapper {
    padding: $popover-padding;
  }

  .transitioningLoader {
    height: $results-max-height;
  }

  .content {
    overflow-y: auto;
    flex-grow: 1;
    padding: 0 14px $popover-padding $popover-padding;

    &:not(.scrollable) {
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
    }

    &.noSearchInput {
      padding-top: $popover-padding;
    }
  }

  .collapsibleGroup {
    margin-top: var(--global-space-single);
  }

  .collapsibleRootGroup,
  .collapsibleGroup {
    .groupTitle {
      @include flex-horizontal;
      width: 100%;
      padding-bottom: var(--global-space-half);
      border-bottom: 1px solid var(--global-color-gray-4);
      justify-content: space-between;

      .titleLeft {
        @include flex-horizontal;
        font-size: 12px;
        color: var(--global-color-dark-blue);
        flex-grow: 1;
        justify-content: space-between;

        &:disabled { background-color: transparent; }

        .groupLabel {
          text-align: left;
          display: inline-block;
          margin-left: var(--global-space-single);

          .groupSize {
            margin-left: 0.8ch;
            font-size: 10px;
          }
        }
      }
    }

    .collapsibleGroup {
      margin-left: var(--global-space-double);
    }

    .collapsibleItemsSection {
      overflow: hidden;
      @include transition(max-height);

      &.close {
        max-height: 0;
      }
    }
  }

  .bottomMenu {
    background-color: white;
    padding: var(--global-space-single) $popover-padding;
    @include flex-horizontal;
    justify-content: flex-end;
    border-top: 1px solid var(--global-color-gray-4);

    button {
      @include rounded;
      font-size: 12px;
      height: var(--global-button-height);

      &.done {
        color: white;
        padding: var(--global-space-half) var(--global-space-double);
      }
    }
  }
}
