@import 'src/global/mixins.scss';

@mixin chart-wrapper {
  @include flex-center;
  height: 285px;
  width: 100%;
  position: relative;
  align-items: stretch;
}

.chartSection {
  @include container;
  box-sizing: content-box;
  height: 100%;

  .chartWrapper {
    @include chart-wrapper;
  }
}
