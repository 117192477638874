@import 'src/global/mixins.scss';

.interactionsDrawer {
  display: flex;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .interactionsBottomSection {
    @include transition(flex-grow);
    flex-grow: 1;
  }
}
