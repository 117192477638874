@import 'src/global/mixins.scss';

.info-modal-wrapper-button {
  @include flex-horizontal;
  justify-content: space-between;
  width: 100%;

  .caret {
    margin-right: var(--global-space-double);
  }
}

.info-modal-platform-dropdown {
  .dropdown-menu {
    width: calc(100% - 2 * var(--global-space-triple));
    overflow-y: auto;
    max-height: 140px;

    .private-channel-name {
      @include flex-horizontal;
      flex-direction: row;
      padding-left: var(--global-space-single);
      padding-right: var(--global-space-single);

      img {
        width: 15px;
        height: 15px;
      }

      span {
        margin: var(--global-space-single);
      }
    }

  }
}

.info-modal-platform {
  @include flex-horizontal;
  .content {
    margin: var(--global-space-single);

    h3 {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      color: var(--global-color-dark-blue);
      margin-bottom: 2px;
    }

    .description {
      font-size: 10px;
      color: var(--global-color-dark-gray);

      .guideLink {
        font-size: 10px;
        margin-left: calc(var(--global-space-half) / 2);
        color: var(--global-color-blue);
        cursor: pointer;
      }
    }
  }
}
