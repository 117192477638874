.skeletonWrapper {
  position: relative;
  overflow: hidden;

  .badgeSkeleton {
    background: var(--global-color-gray-3);
    overflow: hidden;
    margin: var(--global-space-single) 0;
    border-radius: var(--global-space-half);
    width: calc(var(--global-button-height) * 4);
    height: var(--global-button-height);
    padding: var(--global-space-single);
  }
}
