@import 'src/global/mixins.scss';

.container {
  @include flex-horizontal;
  background-color: white;
  text-align: center;
  padding: 20px;
  height: 100vh;
  align-items: stretch;
  box-sizing: border-box;
  overflow: hidden;

  .illustration {
    flex: 1;
    background-image: url('/src/assets/img/loginPage/login-illustration.svg');
    background-position: center;
    background-size: cover;
    background-color: white;
    z-index: 1;
    height: 100%;
    overflow: hidden;
    @include flex-center;

    // Don't show illustration on screens smaller than 800px
    @media (max-width: 800px) {
      max-width: 0;
    }
  }

  .content {
    height: 100%;
    flex: 1;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 0;

    .slidesWrapper {
      position: relative;
      width: 100%;
      min-height: 420px;
      box-sizing: border-box;
      flex-grow: 1;

      --login-btns-border-radius: 50px;

      .slide {
        @include flex-center(column);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        overflow-y: auto;
        transition: opacity 0.8s ease, transform 0.8s ease;

        h1 {
          font-weight: 500;
          font-size: 40px;
          margin-bottom: var(--global-space-double);
          line-height: 1;
          color: var(--global-color-dark-blue);
        }
      }
    }

    footer {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        color: #8F8F8F;
        font-size: 16px;
        padding: 15px;
        font-weight: 400;

        &:hover {
          color: #696969;
        }
      }
    }
  }

  .errorMessage {
    margin: var(--global-space-double) 0 var(--global-space-double) 0;
    font-size: 14px;
    color: var(--global-color-red);
  }
}
