@import 'src/global/mixins.scss';

.message {
  @include flex-center(column);
  width: 100%;
  height: 100%;
  background-color: white;
  padding: var(--global-space-triple) 0;
  box-sizing: border-box;

  h2 {
    color: var(--global-color-dark-blue);
    font-size: 18px;
    line-height: 26px;
    margin-bottom: var(--global-space-half);
    margin-top: var(--global-space-single);
  }

  span {
    color: var(--global-color-dark-gray);
    font-size: 12px;
    max-width: 310px;
    text-align: center;
    line-height: 16px;
  }

  button {
    margin-top: var(--global-space-half);
    color: var(--global-color-blue);
    font-size: 12px;
    font-weight: normal;

    &:hover {
      color: hsl(var(--global-color-blue-h) var(--global-color-blue-s) calc(var(--global-color-blue-l) * 1.1));
    }
  }
}
