@import 'src/global/mixins.scss';

$width-text: 90%;
$height-text: 20px;
$width-paragraph: 96%;
$height-paragraph: 58px;
$width-title: 30%;
$height-title: 16px;
$width-subtitle: 15%;
$height-subtitle: 12px;
$width-avatar: 16px;
$height-avatar: 16px;
$width-badge: 31%;
$height-badge: 26px;
$medium-rounded: 10px;
$fully-rounded: 50%;

.skeleton {
  background: #DDD;
  overflow: hidden;
  margin: 10px 0;
  @include rounded-large;

  &.text {
    width: $width-text;
    height: $height-text;
  }

  &.paragraph {
    width: $width-paragraph;
    height: $height-paragraph;
  }

  &.title {
    width: $width-title;
    height: $height-title;
  }

  &.subtitle {
    width: $width-subtitle;
    height: $height-subtitle;
  }

  &.avatar {
    width: $width-avatar;
    height: $height-avatar;
  }

  &.badge {
    width: $width-badge;
    height: $height-badge;
  }
}

.skeletonWrapper {
  margin: 20px auto;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

/* animation effects */

.shimmerWrapper {
  position: absolute;

  /* parent must be relative position & overflow hidden */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;

  .shimmer {
    width: 50%;
    height: 100%;
    background: rgb(255 255 255 / 20%);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgb(255 255 255 / 20%);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }

  50% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(150%);
  }
}
