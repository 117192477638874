@import 'src/global/mixins.scss';

.restrictedViewBtn {
  @include flex-horizontal;
  @include transition(opacity);
  border: 1px solid var(--global-color-gray-4);
  color: var(--global-color-dark-gray);
  font-size: 12px;
  opacity: 1;
  height: var(--global-button-height);

  .lockIcon {
    width: 14px;
    height: 14px;
    margin-right: var(--global-space-single);
  }

  &.hidden {
    opacity: 0;
  }
}

.restrictedViewPopoverContent {
  @include flex-center(column);
  width: 260px;
  cursor: default;

  .lockIcon {
    width: 24px;
    height: 24px;
    margin: var(--global-space-single);
  }

  .title {
    color: var(--global-color-dark-blue);
    font-weight: 500;
    font-size: 14px;
  }

  .content {
    font-size: 12px;
    line-height: 15px;
    color: var(--global-color-dark-gray);
    margin: var(--global-space-single) var(--global-space-double);
    text-align: center;
  }

  .actionsContainer {
    @include flex-horizontal;
    justify-content: stretch;
    width: 100%;
    border-top: 1px solid var(--global-color-gray-4);
    margin-top: var(--global-space-single);
    padding: var(--global-space-single) var(--global-space-double);
    padding-bottom: 0;
    box-sizing: border-box;
    gap: var(--global-space-single);

    button {
      flex-grow: 1;
      height: var(--global-button-height);
      font-size: 12px;
    }

    .learnMore {
      color: white;
    }

    .transparent {
      color: var(--global-color-dark-gray);
      border: 1px solid var(--global-color-gray-4);
    }
  }
}
