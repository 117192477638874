@import 'src/global/mixins.scss';

.searchable-selection-dropdown {
  position: relative;

  .no-options {
    padding: var(--global-space-single);
  }

  .option {
    @include flex-horizontal;
    cursor: pointer;
    width: 100%;
    text-align: left;
    color: var(--global-color-dark-blue);
    @include transition(background-color);
    background-color: white;
    padding: var(--global-space-single) var(--global-space-double);

    &:hover, &.highlightedOption {
      background-color: var(--global-color-gray-4);
    }

    span {
      overflow: hidden;
      word-wrap: break-word;
    }
  }
}
