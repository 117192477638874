@import 'src/global/mixins.scss';

.interactionsCount {
  font-size: 12px;
  color: var(--global-color-dark-gray);
  line-height: 2;
  @include flex-horizontal;
  transition:
    opacity var(--global-transition-duration) ease,
    height var(--global-transition-duration) ease,
    transform var(--global-transition-duration) ease;
  max-height: 0;
  opacity: 0;
  transform: translateY(100%);

  .versus {
    width: 11px;
    margin: 0 1ch;
    color: var(--global-color-gray-1);
  }

  .spinner {
    transform: translateY(-7px);
  }

  &.visible {
    opacity: 1;
    max-height: 40px; // So the transition is super quick
    box-sizing: border-box;
    transform: translateY(0);
  }

  .badgesContainer {
    display: flex;
    gap: var(--global-space-half);
    flex-flow: row nowrap;
  }

  .summaryInteractionsCount {
    @include flex-horizontal;
    gap: var(--global-space-half);

    .lockIcon {
      width: 10px;
      height: 10px;
    }
  }
}
