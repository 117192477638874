@import 'src/global/mixins.scss';

.bottomPanel {
  @include flex-horizontal;
  flex-direction: column;
  margin: 0 var(--global-space-double);
  opacity: 1;
  width: calc(100% - 2 * var(--global-space-double));
  transition: opacity 0.2s ease, min-height 0.2s ease, height 0.2s ease;
  height: var(--global-bottom-panel-height);
  min-height: var(--global-bottom-panel-height);

  &.transparent {
    opacity: 0.45;
    cursor: default;
    pointer-events: none;
  }

  &.halfOpened {
    $bottom-section-min-height: 100px;
    $bottom-section-height: max(min(378px, 50vh), $bottom-section-min-height);
    height: $bottom-section-height;
    min-height: $bottom-section-height;
  }

  &.opened {
    $bottom-section-height: calc(100vh - var(--global-top-navbar-height) - 2 * var(--global-space-double));
    height: $bottom-section-height;
    min-height: $bottom-section-height;
  }
}
