@import 'src/global/mixins.scss';

.container {
  @include flex-stretch;
  flex-direction: column;
  min-width: 120px;
  max-height: 360px;
  color: var(--global-color-dark-blue);
  padding: var(--global-space-single) 0;

  .scrollableOptionsList {
    padding: var(--global-space-single) 0;
    overflow-y: auto;
  }

  .searchInput {
    margin: 0 var(--global-space-single);
    margin-bottom: var(--global-space-single);

    input {
      border-color: var(--global-color-border-gray);
    }
  }

  .emptyFiltersMessage {
    padding: var(--global-space-double);
  }

  .optionItem {
    @include flex-start;
    width: 100%;
    padding: var(--global-space-single) var(--global-space-double);

    &:hover {
      background-color: var(--global-color-gray-4);
    }

    &.selected {
      color: var(--global-color-blue);
      font-weight: 500;
    }
  }

  .disabledOptionWrapper {
    @include flex-stretch;
    flex-direction: column;

    .optionItem {
      background-color: white;
    }
  }

  .topMenu {
    padding-bottom: var(--global-space-single);
  }

  .bottomMenu {
    @include flex-horizontal;
    justify-content: flex-end;
    padding: var(--global-space-single) var(--global-space-single) 0;

    button {
      font-size: 12px;
      height: var(--global-button-height);
      padding: var(--global-space-half) var(--global-space-double);
    }
  }
}

.separator {
  width: 100%;
  height: 0;
  border-top: 1px solid var(--global-color-border-gray);
}
