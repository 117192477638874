@import 'src/global/mixins.scss';

.title {
  @include flex-horizontal;
  gap: var(--global-space-half);
  flex-wrap: wrap;
  row-gap: 2px;

  > div {
    white-space: nowrap;
  }
}
