.disclaimerWrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--global-space-single);

  .disclaimer {
    color: var(--global-color-gray-11);
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}
