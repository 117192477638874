@import 'src/global/mixins.scss';

.wrapper {
  width: 100%;

  .container {
    height: var(--bubble-chart-height);
    width: 100%;

    .tooltip {
      position: absolute;
      transform: translate(-50%, 30px);
    }
  }
}
