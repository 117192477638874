@import 'src/global/mixins.scss';

$container-gap: calc(5 * var(--global-space-half));

@mixin smooth-transition() {
  transition: transform 400ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.carousel {
  @include rounded-large;
  @include container($container-gap);
  background: var(--global-color-transparent-white);
  padding: var(--global-space-triple);
  overflow: hidden;

  .header {
    @include flex-stretch;
    align-items: center;
    justify-content: space-between;

    .navigation {
      @include flex-stretch;

      .carouselButton {
        width: 24px;
        height: 24px;

        &:disabled {
          background-color: transparent;
          opacity: 0.2;
        }

        .chevronIcon {
          color: var(--global-color-dark-gray);
          width: 100%;
          height: 100%;

          &.next {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .carouselContent {
    @include flex-stretch;
    gap: var(--global-space-double);
    list-style-type: none;

    .carouselItem {
      @include smooth-transition;
      transform: translateX(var(--transform-value));
    }
  }

  &.error {
    .header {
      opacity: 0.3;
    }
  }

  &.skeleton {
    position: relative;
    background-color: var(--global-color-gray-8);
    @include transition(background-color);

    .header {
      align-items: stretch;
    }
  }
}
