@import 'src/global/mixins.scss';

.platformContentScreenWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  min-width: 1024px; // TODO: refactor this ugly fix for smaller screens
  overflow-y: hidden;
  display: flex;

  .platformScreenContentSection {
    height: 100%;
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--global-color-gray-5);

    .platformScreenContent {
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      background-color: var(--global-color-gray-5);
      box-sizing: border-box;

      .platformSearch {
        max-width: 680px;
        margin: var(--global-space-double) auto;
        margin-top: 0;
      }

      &.directoryScreen {
        background-color: white;
      }

      &.padded {
        padding: var(--global-space-double);
      }
    }
  }
}
