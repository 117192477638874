@import 'src/global/mixins.scss';

.container {
  @include shadowed;
  display: flex;
  flex-direction: column;
  gap: var(--global-space-half);
  height: 100%;
  background-color: white;
  border: -1px solid var(--global-color-gray-4);
  padding: var(--global-space-double);

  .content {
    overflow-y: auto;
    padding-bottom: 30px;
    height: 100%;

    .loadingSpinner {
      display: flex;
      align-content: center;
      height: 100%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--global-space-half);

    .summary {
      width: 100%;
      padding-bottom: var(--global-space-double);
    }
  }
}
