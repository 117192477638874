@import 'src/common-ui-components/Badge/badge.module.scss';

.badgesContainer {
  display: flex;
  gap: var(--global-space-half);
  flex-flow: row wrap;

  .customBadge {
    min-height: 0;
    padding: $badge-padding;
  }

  .versus {
    width: 11px;
    margin: 0 1ch;
    color: var(--global-color-gray-1);
  }
}
