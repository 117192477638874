@import 'src/global/mixins.scss';

.sourceTitle {
  width: 100%;
  overflow: hidden;

  &.enterpriseSearchDesign {
    max-width: 470px;
  }

  a {
    padding: 0;
    width: 100%;
  }

  .title {
    color: var(--global-color-es-gray-dark);
    font-size: 12px;
    font-weight: 400;
    text-align: left;

    &.singleLine {
      @include ellipsis;
    }

    &.threeLines {
      @include lines-limit(3);
    }
  }

  .subtitle {
    display: flex;
    justify-content: space-between;
    margin-top: var(--global-space-half);
  }
}
