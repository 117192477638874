@import 'src/global/mixins.scss';

.container {
  @include flex-horizontal;

  .description {
    margin-left: var(--global-space-single);
    flex: 1;

    .url {
      color: var(--global-color-blue);
      overflow-wrap: anywhere;
    }
  }
}
