@import 'src/global/mixins.scss';

.showAll {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: var(--global-color-blue);
  opacity: 1;
  @include transition(opacity);
  background-color: transparent;

  &:hover {
    opacity: 0.5;
  }

  .arrowIcon {
    display: inline-block;
    @include transition(transform);
    transform: rotate(0);

    &.flipped {
      transform: rotate(-180deg);
    }
  }
}
