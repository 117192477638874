@import 'src/global/mixins.scss';

.hiddenItemsBadge {
  cursor: pointer;
}

.hiddenItemsTooltip {
  @include container;
  gap: calc(1.5 * var(--global-space-single));
  padding: var(--global-space-single);

  .item {
    @include flex-horizontal;
    gap: var(--global-space-single);
    line-height: 1;
  }
}

.rounded {
  @include rounded;
}
