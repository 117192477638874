@import 'src/global/mixins.scss';

.trendBadge {
  @include rounded;
  padding: calc(0.5 * var(--global-space-half)) var(--global-space-half);

  .icon {
    width: 14px;
  }

  &.noChange {
    color: var(--global-color-gray-2);
    background-color: var(--global-color-gray-5);
  }

  &.increasing {
    color: var(--global-color-green);
    background-color: var(--global-color-light-green);
  }

  &.decreasing {
    color: var(--global-color-red);
    background-color: var(--global-color-light-red);
  }
}
