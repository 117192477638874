@import 'src/global/mixins.scss';
@import 'src/common-ui-components/MultiImages/ProfileImage/style.module.scss';

$announcement-content-gap: var(--global-space-single);

.announcementContent {
  display: grid;
  grid-auto-columns: $medium-image-dimension 1fr;
  grid-template:
    'announcer-avatar   announcer-name'
    '.                  slack-message';
  grid-gap: $announcement-content-gap;

  .messageContainer {
    grid-area: slack-message;
    @include container;
    gap: var(--global-space-single);
    overflow: hidden;
  }
}
