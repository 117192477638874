@import 'src/global/mixins.scss';

$negative-margin: -8px;

@mixin round-image {
  border-radius: 50%;
}
@mixin square-image {
  border-radius: 10%;
}

.withHalfCircleBorder {
  border-radius: 10rem;
}

.withSquareRoundBorder {
  border-radius: 4px;
}

.images {
  @include flex-horizontal;
  padding-right: calc(-1 * $negative-margin);
  border: 2px solid var(--global-color-gray-4);
  background-color: var(--global-color-gray-4);
  width: max-content;

  .personImageWrapper,
  .organizationImageWrapper {
    margin-right: $negative-margin;
  }

  .personImageWrapperHidden,
  .organizationImageWrapperHidden {
    margin-left: calc(-1 * $negative-margin + 2px);
    margin-right: $negative-margin;
  }

  .personImageWrapperHiddenSm,
  .organizationImageWrapperHiddenSm {
    margin-left: calc(-1 * $negative-margin - 2px);
    margin-right: $negative-margin;
  }

  .personImageWrapperHiddenMd,
  .organizationImageWrapperHiddenMd {
    margin-left: calc(-1 * $negative-margin);
    margin-right: $negative-margin;

    button {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
