@import 'src/global/mixins.scss';

.colorSquare {
  @include rounded;
  box-sizing: border-box;

  &.medium {
    width: 20px;
    height: 20px;
  }

  &.small {
    width: 10px;
    height: 10px;
  }

  &.noColor {
    border: 1px solid var(--global-color-gray-3);
    background-color: var(--global-color-gray-4);
  }

  &.bordered {
    border: 1px solid var(--global-color-gray-3);
  }
}
