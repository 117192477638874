@import 'src/global/mixins.scss';
@import 'src/common-ui-components/Pagination/style.scss';
/* stylelint-disable */

.ant-dropdown.ant-dropdown-placement-bottomRight, .ant-dropdown.ant-dropdown-placement-bottomLeft {
  max-width: 400px;

  .ant-table-filter-dropdown {
    border: 1px solid var(--global-color-border-gray);
  }
}

.tableWrapper {
  position: relative;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .ant-table {
    background-color: transparent;
  }

  .ant-table-column-sorters > .ant-table-column-sorter {
    margin-left: 0;
    margin-right: 4px;
  }

  .ant-table-column-sorters > .ant-table-column-title {
    direction: ltr;
  }

  .ant-table-container .ant-table-header .ant-table-thead > tr > th,
  .ant-table-container .ant-table-body .ant-table-tbody > tr > td {
    text-align: left;
    white-space: nowrap;
    padding: var(--global-space-single) var(--global-space-double);
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: var(--global-color-dark-gray);
  }

  th.drop-over-left {
    border-left: 2px dashed var(--global-color-blue);
  }
  th.drop-over-right {
    border-right: 2px dashed var(--global-color-blue);
  }
  .draggable-header-cell:not(.ant-table-column-has-sorters),
  .draggable-header-cell.ant-table-column-has-sorters span {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }

  td.ant-table-column-sort {
    background: transparent;
  }

  .ant-table-container .ant-table-header .ant-table-thead > tr > th {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--global-color-dark-gray);
    background-color: white;
    padding: var(--global-space-double);

    .actionIcon {
      width: 14px;
      height: 14px;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }

    &:hover {
      background-color: var(--global-color-gray-4);
      .actionIcon {
        opacity: 1;
      }
    }
  }

  .ant-table-body table {
    border-radius: 0 0 8px 8px;
    line-height: normal;
    color: var(--global-color-dark-blue);
    border: 1px solid var(--global-color-border-gray);
    border-top: 0;
    background-color: white;
  }

  .ant-table-sticky-scroll {
    display: none;
  }

  .ant-table-container .ant-table-sticky-holder {
    z-index: var(--global-z-index-drawer);
    background-color: transparent;
    border-radius: 0;
  }

  .ant-table-header table {
    border-radius: 8px 8px 0 0;
    line-height: normal;
    color: var(--global-color-dark-blue);
    border: 1px solid var(--global-color-border-gray);
    overflow: hidden;

    th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      height: 55%;
      background-color: var(--global-color-gray-3);
    }
  }

  .ant-table-thead th.ant-table-column-sort::before {
    background-color: var(--global-color-gray-3) !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: var(--global-color-hover-gray);
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  .ant-spin-nested-loading .ant-spin .spinnerWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    z-index: calc(var(--global-z-index-bottom-panel) + 1);
    padding-top: var(--global-space-double);
  }

  .ant-select .ant-select-selector {
    @include rounded;
    @include flex-horizontal;
    border-color: var(--global-color-gray-4);
    color: var(--global-color-dark-gray);

    &:hover {
      border-color: var(--global-color-gray-4);
      background-color: var(--global-color-gray-4);
    }
  }

  .ant-select-single.ant-select-sm:not(.ant-select-customize-input),
    .ant-select-selector {
    height: 25px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select,
    .ant-select-selector {
    border-color: var(--global-color-gray-4);
    box-shadow: none;
  }

  .ant-select-dropdown {
    @include rounded;
  }

  .ant-select-item {
    color: var(--global-color-dark-gray);
    &:hover {
      background-color: var(--global-color-gray-4);
    }

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: var(--global-color-dark-gray);
      font-weight: 400;
      background-color: var(--global-color-gray-4);
    }
  }
}

.ant-dropdown {
  max-width: 160px;
}

.ant-dropdown-hidden {
  visibility: hidden;
}

.ant-table-filter-trigger {
  margin: 0;
}
