@import 'src/global/mixins.scss';

.popoverSection {
  @include flex-stretch;
  flex-direction: column;
  padding: var(--global-space-double) 0;
  border-bottom: 1px solid var(--global-color-gray-4);

  &:last-of-type {
    border-bottom: none;
  }

  .headerWrapper {
    @include flex-horizontal;
    justify-content: space-between;
    padding: 0 var(--global-space-double);
    margin-bottom: var(--global-space-double);

    h3 {
      color: var(--global-color-dark-blue);
      font-weight: 500;
      font-size: 14px;
    }
  }

  .sectionInnerWrapper {
    @include flex-stretch;
    flex-direction: column;
    padding: 0 var(--global-space-double);

    &.noPadding {
      padding: 0;
    }
  }
}
