@import 'src/global/mixins.scss';

.platformScreen {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentContainerWrapper {
  display: flex;
  height: 100vh;
  position: relative;
}
