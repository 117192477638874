@import 'src/global/mixins.scss';

$switch-height: 22px;
$switch-padding: 2px;
$switch-ball-size: 18px;

.switchWrapper {
  background-color: var(--global-color-gray-2);
  height: $switch-height;
  border-radius: $switch-height * 0.5;
  cursor: pointer;
  position: relative;
  width: 40px;
  @include transition(all);

  &.disabled {
    background-color: var(--global-color-gray-3);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.loading {
    opacity: 0.5;
  }

  &.customContent {
    width: max-content;
    @include flex-horizontal;
  }

  $inside-text-big-padding: calc($switch-ball-size + var(--global-space-single));
  $inside-text-small-padding: var(--global-space-single);

  .insideText {
    color: white;
    font-size: 12px;
    padding-right: $inside-text-small-padding;
    padding-left: $inside-text-big-padding;
    @include transition(padding);
  }

  .switchBall {
    @include flex-center;
    position: absolute;
    height: $switch-ball-size;
    width: $switch-ball-size;
    top: $switch-padding;
    border-radius: $switch-height * 0.5;
    background-color: white;
    left: $switch-padding;
    @include transition(left);

    &.transparentContent {
      @include transparent-content;
    }

    .spinner {
      padding: $switch-padding;
    }

    .customIcon {
      padding: 3px;
    }
  }

  &.selected {
    background-color: var(--global-color-blue);

    &.yellow {
      background-color: var(--global-color-yellow);
    }

    .insideText {
      padding-right: $inside-text-big-padding;
      padding-left: $inside-text-small-padding;
    }

    .switchBall {
      left: calc(100% - $switch-ball-size - $switch-padding);
    }
  }
}
