@import 'src/global/mixins.scss';

.container {
  @include container(var(--global-space-single));
  margin-top: calc(var(--global-space-single) * -1);
  padding: var(--global-space-double) calc(var(--global-space-single) * 5 + var(--global-space-half));
  padding-top: var(--global-space-triple);
  background-color: var(--global-color-transparent-white);
  border-radius: 0 0 8px 8px;

  .bigText {
    font-size: 12px;
  }

  .smallText {
    font-size: 10px;
  }

  .highlightedText {
    color: var(--global-color-blue);
    font-weight: 500;
    font-style: italic;
  }
}
