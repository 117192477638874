@import 'src/global/mixins.scss';

$font-size: 18px;

.dynamicTitle {
  @include flex-horizontal;
  flex-wrap: wrap;
  font-size: $font-size;
  color: var(--global-color-dark-gray);

  & > * {
    display: inline-block;
    font-size: $font-size;
  }

  .config {
    @include flex-horizontal;
    background-color: transparent;
    color: var(--global-color-dark-blue);
    font-size: $font-size;
    font-weight: 500;

    &:not(.static) {
      border-bottom: 1px solid var(--global-color-dark-blue);
      transform: translateY(1px);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.caretContainer {
  margin-left: auto;
}

.verticalDropdown {
  display: flex;
  flex-direction: column;

  .option {
    @include flex-horizontal;
    padding: var(--global-space-single) var(--global-space-double);
    color: var(--global-color-dark-blue);

    &.selected {
      background-color: var(--global-color-gray-4);
    }
  }
}
