@import 'src/global/mixins.scss';

.actionableBadge {
  font-size: 12px;
  position: relative;
  padding: var(--global-space-single);
  min-height: var(--global-button-height);
  max-width: 100%;
  color: var(--global-color-dark-gray);
  @include flex-horizontal;
  @include rounded;
  box-sizing: border-box;
  line-height: normal;
  display: inline-flex;
  background-color: var(--global-color-gray-5);
  gap: var(--global-space-half);
  word-break: break-all;

  .badgeLabel {
    @include flex-horizontal;
  }

  &::before {
    content: '';
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    @include rounded;
    @include transition(opacity);
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover::before {
      opacity: 0.2;
    }
  }

  svg {
    width: 12px;
    height: 12px;
    @include transition(filter);
  }

  .removeIcon {
    margin-left: 1px;
    font-size: 15px;
    line-height: 0;
  }
}
