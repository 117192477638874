@import 'src/global/mixins.scss';

$interactions-table-header-gap: var(--global-space-single);

.interactions {
  background-color: white;
  @include flex-horizontal;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid var(--global-color-gray-4);
  @include shadowed;
  @include rounded-card;
  box-sizing: border-box;

  --top-section-height-when-open: 80px;

  .topSection {
    @include flex-horizontal;
    @include rounded-card;
    border: none;
    box-shadow: none;
    justify-content: space-between;
    width: 100%;
    height: var(--global-bottom-panel-height);
    padding: var(--global-space-double);
    gap: $interactions-table-header-gap;
    box-sizing: border-box;

    &.opened {
      height: auto;
    }

    &.clickable {
      cursor: pointer;
    }

    .caretBtn {
      padding: var(--global-space-single);
      border: 1px solid var(--global-color-gray-4);
      height: var(--global-button-height);
    }
  }

  .bottomSection {
    overflow-y: auto;
    padding: 0 var(--global-space-double);
    width: 100%;
    box-sizing: border-box;
  }
}
