@import 'src/global/mixins.scss';

.sourcesList {
  max-height: var(--height);
  overflow: hidden;

  &.animated {
    @include transition(max-height);
  }
}
