@import 'src/global/mixins.scss';
@import 'src/common-ui-components/Dropdown/keyboardNavigationStyle.module.scss';

.sectionLabel {
  @include label;
  font-size: 12px;
  margin-bottom: var(--global-space-half);
  padding: 0 var(--global-space-double);
  padding-top: var(--global-space-double);

  &:not(:first-of-type) {
    border-top: 1px solid var(--global-color-gray-4);
    margin-top: var(--global-space-single);
  }
}

.searchBarResultsItemWrapper {
  @include transition(background-color);
  width: 100%;
  box-sizing: border-box;
  padding: var(--global-space-single) var(--global-space-double);

  &:hover,
  &.selected {
    background-color: var(--global-color-hover-gray);
  }

  .enterIcon {
    @include enter-icon;
  }

  &.selected .enterIcon {
    @include selected-enter-icon;
  }

  .searchResultItem {
    @include flex-horizontal;

    .circleIcon {
      display: flex;
      border-radius: 50%;
      padding: 6px;
      background-color: var(--global-color-gray-4);

      .icon {
        width: 16px;
        fill: none;
        stroke-width: 2px;
        color: var(--global-color-dark-gray);
      }
    }

    .itemData {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      margin-left: var(--global-space-single);
      text-align: start;
      font-size: 12px;
      gap: var(--global-space-half);

      .title {
        font-size: 14px;
        color: var(--global-color-dark-blue);
        font-weight: 400;
      }

      .subtitle {
        color: var(--global-color-dark-gray);
      }
    }

    .ellipsisIcon {
      background-color: var(--global-color-gray-4);
    }
  }
}
