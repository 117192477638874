@import 'src/global/mixins.scss';

.navbarButton {
  @include rounded;
  @include flex-horizontal;
  width: 100%;
  transition:
    background-color var(--global-transition-duration) ease,
    color var(--global-transition-duration) ease,
    padding var(--global-transition-duration) ease;
  padding: var(--global-space-single) calc(var(--global-space-half) * 3);
  color: var(--global-color-dark-gray);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;

  --hover-background-color: var(--global-color-gray-5);

  .caret {
    @include transition(opacity);
    transform: rotate(-90deg);
    margin-left: auto;
    margin-right: var(--global-space-half);
    opacity: 1;

    svg {
      width: 16px;
      margin-left: 2px;
    }

    &.minimized {
      opacity: 0;
    }
  }

  &:not(.minimized) {
    padding-right: 0;
  }

  &:hover {
    background-color: var(--hover-background-color);
  }

  &.selected {
    background-color: var(--global-color-light-blue);
  }
}
