@import 'src/global/mixins.scss';
@import 'src/es-src/screens/HomeScreen/components/ResultSection/SearchResultCard/style.module.scss';

.skeleton {
  @extend .searchResultCard;
  @include slide-upwards-animation;
  background-color: var(--global-color-gray-8);
  overflow: hidden;

  .spinnerContainer {
    @include flex-center;
    width: 24px;
    height: 24px;
  }

  .resultDetails {
    gap: var(--global-space-single);

    .skeletonColumContainer {
      @include container;
      gap: var(--global-space-single);

      .skeletonRowContainer {
        @include flex-stretch;
        gap: var(--global-space-single);
      }
    }
  }
}
