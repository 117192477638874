@import 'src/global/mixins.scss';

$small-image-dimension: 18px;
$medium-image-dimension: 32px;
$big-image-dimension: 80px;

.profileImage {
  @include flex-center;
  object-fit: cover;
  border-radius: 50%;
  border-color: var(--border-color);
  border-style: solid;
  color: var(--global-color-gray-2);
  text-align: center;

  &.external {
    border-color: var(--global-color-dark-gray);
  }

  &.lightBorders {
    border-color: var(--global-color-gray-4);
  }

  &.noBorder {
    border: none;
  }

  &.small {
    width: $small-image-dimension;
    height: $small-image-dimension;
    font-size: 14px;
    border-width: 0.5px;

    &.external {
      border-radius: 4px;
    }
  }

  &.medium {
    width: $medium-image-dimension;
    height: $medium-image-dimension;
    font-size: 18px;
    border-width: 1px;

    &.external {
      border-radius: 4px;
    }
  }

  &.big {
    width: $big-image-dimension;
    height: $big-image-dimension;
    font-size: 60px;
    border-width: 2px;

    &.external {
      border-radius: 8px;
    }
  }
}
