@import 'src/global/mixins.scss';

.dropdownBtn {
  @include rounded;
  @include flex-horizontal;
  justify-content: space-between;
  padding: var(--global-space-single);
  height: var(--global-button-height);
  font-size: 12px;
  color: var(--global-color-dark-gray);
  background-color: var(--global-color-gray-5);

  span {
    white-space: nowrap;
    margin-right: var(--global-space-single);
  }
}

.dropdownMenu {
  @include flex-stretch;
  flex-direction: column;
  top: 5px;
  overflow: visible;

  .dropdownLabel {
    font-weight: 500;
    color: var(--global-color-dark-gray);
    padding: var(--global-space-single) var(--global-space-double);
  }

  .option {
    @include flex-horizontal;
    padding: var(--global-space-single) var(--global-space-double);
    text-align: start;

    &.selected {
      background-color: var(--global-color-gray-4);
    }

    .caret {
      transform: rotate(180deg);
      margin-left: var(--global-space-double);
      color: var(--global-color-dark-gray);
    }
  }
}
