@import 'src/global/mixins.scss';

.filterWrapper {
  @include flex-start;
  flex-direction: column;
  padding: var(--global-space-double);
  border-bottom: 1px solid var(--global-color-gray-4);
  @include transition(border-bottom-color);

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .filterHeader {
    @include flex-start;
    justify-content: space-between;
    width: 100%;
    color: var(--global-color-dark-gray);

    h3 {
      @include flex-horizontal;
      @include label;
      margin-bottom: var(--global-space-double);

      svg {
        width: 16px;
        height: 16px;
        margin-right: var(--global-space-single);
      }
    }

    .manageLink {
      @include transition(opacity);
      opacity: 0;
      font-size: 12px;
      text-decoration: underline;
      color: var(--global-color-blue);

      &.visible {
        opacity: 1;
      }
    }
  }

  .filterContent {
    @include flex-start;
    flex-direction: column;
    gap: var(--global-space-single);
    max-width: 100%;
  }
}
