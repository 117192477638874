@import 'src/global/mixins.scss';
@import 'src/screens/platform/cross-platform-components/Interactions/components/InteractionsSummary/style.module.scss';

.metadata {
  width: 275px;
  flex-shrink: 0;
  border-left: 1px solid var(--global-color-gray-4);
  padding: 0 var(--global-space-triple);
  padding-bottom: var(--global-space-triple);
  @include container;

  h3 {
    font-weight: 500;
    font-size: 14px;
    color: var(--global-color-dark-blue);
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: var(--global-space-single);

    h4 {
      font-weight: 500;
      font-size: 12px;
      color: var(--global-color-dark-gray);
    }
  }

  .participantsSection {
    display: flex;
    flex-direction: column;
    gap: var(--global-space-single);

    .images {
      @include flex-horizontal;
      gap: var(--global-space-single);
      flex-wrap: nowrap;
    }
  }

  .skeleton {
    @include flex-horizontal;
    @include loading-skeleton-blink;
    gap: var(--global-space-single);
    flex-flow: row wrap;
  }
}
