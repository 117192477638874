@import 'src/global/mixins.scss';

.caret {
  @include transition(transform);
  transform: rotate(0deg);
  line-height: 0;

  &.open {
    transform: rotate(-180deg);
  }
}
