@import 'src/global/mixins.scss';

section.analyticsScreen {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  padding: var(--global-space-double);
  @include rounded-card;
  @include shadowed;
  position: relative;
  height: auto;
  gap: 15px;
}
