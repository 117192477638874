@import 'src/global/mixins.scss';

.organizationBadge {
  @include flex-horizontal;
  gap: var(--global-space-single);

  .details {
    @include flex-start;
    flex-direction: column;
    gap: var(--global-space-half);
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: var(--global-color-dark-gray);
  }
}
