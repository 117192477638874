@import 'src/global/mixins.scss';

$tenant-logo-size: 40px;
$navbar-toggle-button-size: 29px;
$navbar-toggle-icon-size: 14px;
$left-navbar-minimized-width: 40px;

@mixin navbar-toggle-button-styles {
  position: absolute;
  @include flex-center;
  width: $navbar-toggle-button-size;
  height: $navbar-toggle-button-size;
  border-radius: 50%;
  background-color: white;
  border: 1px solid var(--global-color-gray-6);
  z-index: var(--global-z-index-navbar);
  opacity: 0;
  top: 55px;
  right: 0;
  @include transition(opacity);

  .arrowIcon {
    width: $navbar-toggle-icon-size;
    height: $navbar-toggle-icon-size;
    color: var(--global-color-gray-11);
  }

  &:hover .arrowIcon {
    opacity: 0.5;
  }
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex-shrink: 0;
  width: var(--global-left-navbar-width);
  max-width: var(--global-left-navbar-width);
  z-index: var(--global-z-index-navbar);
  padding: calc(var(--global-space-half) * 5);
  background-color: white;
  border-right: 1px solid var(--global-color-gray-6);
  box-sizing: content-box;
  transition:
    max-width var(--global-transition-duration) ease-in-out,
    padding var(--global-transition-duration) ease-in-out;

  --text-max-width: calc(200px - 2 * var(--global-space-double));

  .headerHomeIcon {
    @include flex-horizontal;
    gap: calc(var(--global-space-half) * 3);

    .logoText {
      opacity: 1;
      max-width: var(--text-max-width);
      transition:
        max-width var(--global-transition-duration) ease-in-out,
        opacity var(--global-transition-duration) ease-in-out;
    }

    .logo {
      width: $tenant-logo-size;
      height: $tenant-logo-size;
    }
  }

  .toggleButton {
    @include navbar-toggle-button-styles;
    transform: translateX(50%);
  }

  .screenButtons {
    display: flex;
    flex-direction: column;
    gap: var(--global-space-half);
    margin-top: var(--global-space-triple);
  }

  &:hover .toggleButton {
    opacity: 1;
  }

  &.minimized {
    max-width: $left-navbar-minimized-width;
    padding: calc(var(--global-space-half) * 5) var(--global-space-single) calc(var(--global-space-half) * 5);

    .headerHomeIcon .logoText {
      max-width: 0;
      opacity: 0;
    }

    .toggleButton .arrowIcon {
      transform: rotate(180deg);
    }

    .screenButtons {
      display: flex;
      flex-direction: column;
      gap: var(--global-space-half);
    }
  }
}
