@import 'src/global/mixins.scss';
@import 'src/screens/platform/cross-platform-components/Navbar/components/navbarButtonStyle.module.scss';

$profile-image-size: 36px;

.profileButton {
  @extend .navbarButton;
  font-weight: 500;
  gap: calc(var(--global-space-half) * 3);

  &.minimized {
    padding-left: 2px;
  }

  &:hover {
    background: none;
  }

  .imageWrapper {
    position: relative;

    .tenantLogo {
      position: absolute;
      bottom: -8px;
      right: -5px;
      width: 20px;
      background: white;
      padding: 1px;
      border: 1px solid var(--global-color-gray-6);
      outline: 1px solid white;
      border-radius: 2px;
    }
  }

  .profileImage {
    width: $profile-image-size;
    height: $profile-image-size;
    background-color: var(--global-color-es-gray-light);
    border-radius: 50%;
    flex-shrink: 0;
    @include transition(outline-color);
    outline: 5px solid transparent;

    &.selected {
      outline-color:
        hsl(
          var(--global-color-blue-h) var(--global-color-blue-s) calc(var(--global-color-blue-l) * 2)
        );
    }

    &:hover:not(.selected) {
      outline-color: var(--hover-background-color);
    }
  }
}
