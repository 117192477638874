@import 'src/global/mixins.scss';

$left-panel-collapsable-width: 65px;

.leftPanel {
  height: 100%;
  width: var(--global-left-panel-width);
  position: relative;
  color: var(--global-color-dark-blue);
  z-index: var(--global-z-index-master-filters);
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--global-color-gray-4);
  @include shadowed;
  @include transition(width);

  &.collapsed {
    width: $left-panel-collapsable-width;
  }

  &:not(.collapsed) {
    min-width: var(--global-left-panel-width);
  }
}
