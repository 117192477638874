@import 'src/global/mixins.scss';

.rightPanel {
  z-index: var(--global-z-index-right-panel);
  position: absolute;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
}
