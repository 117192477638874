.h1 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 38px;
}

.h2 {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.h3 {
  color: #555;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.h4 {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.paragraph {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  word-break: break-word;
}
