.iconsContainer {
  width: 120px;
  display: flex;
  flex-wrap: wrap;
}

.tooltip {
  height: fit-content;
  min-height: 40px;
  width: fit-content;
  min-width: 320px;
  padding: var(--global-space-double);
}

.image {
  :not(:first-child) {
    margin-left: var(--global-space-half);
  }

  span {
    border: 1px solid var(--global-color-gray-4);
  }
}
