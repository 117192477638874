@import 'src/global/mixins.scss';

.warningIcon {
  width: 16px;
  height: 16px;
  margin-left: var(--global-space-single);
}

.popoverButton {
  @include flex-horizontal;
}

.dropdown {
  padding: 0;
}

.infoPopover {
  width: 280px;
  height: 200px;
  display: flex;
  flex-direction: column;

  section {
    @include flex-center(column);
    padding: 0 var(--global-space-triple);
    flex: auto;

    h3 {
      font-size: 14px;
      font-weight: 500;
      color: var(--global-color-dark-blue);
      margin-bottom: var(--global-space-single);
    }

    span.content {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: var(--global-color-dark-gray);
    }

    .warningIcon {
      width: 22px;
      height: 22px;
      margin-bottom: var(--global-space-double);
      color: var(--global-color-yellow);
    }
  }

  footer {
    @include flex-center;
    border-top: 1px solid var(--global-color-gray-4);
    padding: var(--global-space-single) var(--global-space-triple);
    height: 45px;
    box-sizing: border-box;

    button {
      width: 100%;
      height: 100%;
    }
  }
}
