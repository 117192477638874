@import 'src/global/mixins.scss';

$tick-height: 35px;

.shareOfAttentionTicks {
  padding-top: $tick-height;

  .tick {
    @include flex-center;
    text-align: center;
    font-size: 12px;
    color: var(--global-color-dark-gray);
    height: $tick-height;
  }

  .truncatedTick {
    cursor: pointer;
  }
}
