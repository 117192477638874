@import 'src/global/mixins.scss';
@import 'src/common-ui-components/Badge/badge.module.scss';

.metricBadge {
  @extend .badge;
  @include flex-horizontal;
  display: inline-flex;
  border: 1px solid var(--global-color-gray-4);
  text-transform: capitalize;
  white-space: nowrap;
  background-color: transparent;

  .circle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: var(--global-space-half);
  }
}
