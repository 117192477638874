@import 'src/global/mixins.scss';

.scanningScreen {
  @include flex-center(column);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: var(--global-top-navbar-height);
  z-index: var(--global-z-index-master-filters);
  background-color: white;

  .topShadow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: var(--global-color-gray-4);
    @include shadowed;
  }

  .loader {
    margin-bottom: var(--global-space-triple);
    margin-top: -80px; // it doesn't look centered without moving it a bit up
  }

  h2 {
    font-size: 26px;
    font-weight: 500;
    color: var(--global-color-dark-blue);
  }

  p {
    font-size: 18px;
    color: var(--global-color-dark-gray);
  }
}
