@import 'src/global/mixins.scss';

.loginMainSlide {
  &.appearing {
    opacity: 1;
    transform: translateX(0);
  }

  &.disappearing {
    opacity: 0;
    transform: translateX(-100%);
  }

  .loginButtonsWrapper {
    margin-top: var(--global-space-quadruple);
    width: 350px;
    max-width: 100%;

    .loginButton {
      width: 100%;

      svg {
        padding: 2px;
      }

      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }
}
