@import 'src/global/mixins.scss';

.dropdownMenu {
  @include rounded;
  z-index: var(--global-z-index-dropdown);
  background-color: white;
  padding: var(--global-space-single) 0;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
  font-size: 12px;
  overflow: auto;
}
