@import 'src/global/mixins.scss';

.modalWrapper {
  @include flex-horizontal;
  flex-direction: column;
  padding: calc(2 * var(--global-space-quadruple));
  gap: var(--global-space-double);

  .warningIcon {
    width: 36px;
    color: var(--global-color-yellow);
  }

  .warningText {
    text-align: center;
    line-height: 1.5;

    h2 {
      font-size: 18px;
      font-weight: 500;
      color: var(--global-color-dark-blue);
    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      color: var(--global-color-dark-gray);
    }
  }
}
