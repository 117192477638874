@import 'src/global/mixins.scss';

.square {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: var(--global-color-gray-5);
  border: 1px solid var(--global-color-gray-4);
  transition: background-color 0.1s ease, border-color 0.1s ease;
}

.checkbox {
  @include flex-horizontal;
  font-size: 14px;

  &.partialCheck {
    input[type='checkbox'] ~ .square {
      background-color: var(--global-color-dark-gray);
      border-color: var(--global-color-dark-gray);
    }

    input[type='checkbox'] ~ .square::after {
      content: ' ';
      background-image: url('/src/assets/img/icon/white-minus.svg');
      background-size: 100%;
    }
  }

  &.checkboxWhite:not(.partialCheck) {
    input[type='checkbox'] ~ .square {
      background-color: white;
    }
  }

  &.disabled {
    color: var(--global-color-gray-2);
  }

  .checkboxWrapper {
    position: relative;

    input[type='checkbox']:checked ~ .square {
      background-color: var(--global-color-dark-gray);
      border-color: var(--global-color-dark-gray);
    }

    input[type='checkbox']:disabled ~ .square {
      background-color: var(--global-color-gray-3);
      border-color: var(--global-color-gray-3);
    }

    input[type='checkbox'],
    input[type='checkbox'] ~ .square::after {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      color: white;
    }

    input[type='checkbox'] {
      cursor: pointer;
      opacity: 0;
    }

    input[type='checkbox']:checked ~ .square::after {
      content: ' ';
      -webkit-mask: url('/src/assets/img/icon/check.svg');
      background-size: 100%;
      background-color: white;
    }

    ~ span {
      margin-left: var(--global-space-single);
      text-align: start;
    }
  }
}
