@import 'src/global/mixins.scss';

.interactionsSummary {
  @include transition(opacity);
  display: flex;
  align-items: stretch;
  min-height: 100%;
  position: relative;
  opacity: 1;
  justify-content: space-between;
  gap: var(--global-space-double);

  &.hidden {
    opacity: 0;
  }

  &.fullHeight {
    min-height: 100vh;
    overflow-y: hidden;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--global-space-double);
    color: var(--global-color-dark-blue);
  }

  .loadingSkeleton {
    color: var(--global-color-dark-gray);
    @include loading-skeleton-blink;
  }
}
