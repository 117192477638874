@import 'src/global/mixins.scss';

.container {
  @include flex-center;
  @include rounded;
  padding: var(--global-space-single);
  border: 1px solid var(--global-color-gray-4);
  width: fit-content;

  .checkbox {
    font-size: 12px;

    span {
      color: var(--global-color-dark-gray);
    }
  }
}
