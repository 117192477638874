@import 'src/global/mixins.scss';

$card-width: 280px;
$font-size: 12px;
$max-lines: 3;
$line-height: 1.3;
$header-height: calc($font-size * $max-lines * $line-height);

@mixin centered-header {
  display: flex;
  align-items: center;
  height: $header-height;
}

.interactionDetails {
  @include flex-stretch;
  @include rounded-large;
  background-color: white;
  padding: calc(3 * var(--global-space-half)) var(--global-space-single);
  position: relative;
  gap: var(--global-space-single);
  box-shadow: 0 4px 12px rgb(0 0 0 / 0%);
  @include transition(box-shadow);
  width: $card-width;
  flex-grow: 1;

  .appLogo {
    @include centered-header;
  }

  .interactionContent {
    @include container(var(--global-space-single));
    justify-content: space-between;
    overflow: hidden;
    flex-grow: 1;

    .title {
      @include centered-header;

      .text {
        @include lines-limit($max-lines);
        color: var(--global-color-es-gray-dark);
        font-size: $font-size;
        font-weight: 400;
        text-align: left;
        line-height: $line-height;
        word-break: break-word;
        overflow: hidden;
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: var(--global-color-dark-gray);
      opacity: 0.15;
    }

    .detail {
      font-size: 10px;
      font-weight: 400;
      color: var(--global-color-es-gray-light);
    }

    .footer {
      display: flex;
      align-items: center;
    }
  }

  .externalLink {
    opacity: 0;
    @include transition(opacity);

    .externalLinkIcon {
      color: var(--global-color-dark-gray);
      width: 10px;
      height: 10px;
    }
  }

  &:hover {
    @include shadowed;

    .externalLink {
      opacity: 1;
    }
  }

  &.skeleton {
    .interactionContent {
      .title {
        width: 90%;
      }
    }
  }
}
