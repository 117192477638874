@import 'src/global/mixins.scss';

.headerLeftSection {
  display: flex;
  flex-direction: column;
  gap: var(--global-space-half);
  text-align: left;
  flex-grow: 1;
  height: 100%;

  .title {
    display: flex;
    align-items: center;
    gap: var(--global-space-half);

    h2 {
      color: var(--global-color-dark-blue);
      font-size: 18px;
      font-weight: 500;
    }

    .badge {
      transform: translateY(1.2px); // To align to bottom of text
      @include transition(opacity);
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }

  .subtitle {
    height: 26px;
    white-space: nowrap;
  }
}
