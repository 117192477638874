@import 'src/global/mixins.scss';

.answer {
  @include container(var(--global-space-quadruple));
  @include rounded-large;
  background: var(--global-color-gray-5);
  padding: var(--global-space-double);

  .separatorOverride {
    padding-top: var(--global-space-single);
    margin-bottom: var(--global-space-single);
    border-color: var(--global-color-gray-2);
  }
}
