@import 'src/global/mixins.scss';

@mixin input {
  @include rounded;
  outline: none;
  width: 100%;
  height: var(--global-button-height);
  box-sizing: border-box;
  background-color: var(--global-color-gray-5);
  border: 1px solid var(--global-color-gray-4);
  color: var(--global-color-dark-blue);
  caret-color: var(--global-color-blue);
  font-size: 12px;
  padding: 0 var(--global-space-single);
  @include transition(background-color);
}

.inputWrapper {
  position: relative;

  input {
    @include input;

    &.transparent {
      background-color: white;
    }

    &.paddedLeft {
      padding-left: var(--global-space-triple);
    }

    &:focus {
      background-color: white;
      border-color: var(--global-color-gray-2);
    }
  }
}
