@import 'src/global/mixins.scss';

.button {
  background-color: white;
  cursor: pointer;
  @include transition(background-color);

  &.transparent {
    background-color: transparent;
  }

  &.padded {
    padding: var(--global-space-single) var(--global-space-double);
  }

  &.rounded {
    @include rounded;
  }

  &.gray {
    &:hover { background-color: var(--global-color-gray-4); }
    &:active { background-color: var(--global-color-gray-4); }
  }

  &.yellow:not(.disabled) {
    color: white;
    background-color: var(--global-color-yellow);
  }

  &.blue:not(.disabled) {
    color: white;
    background-color: var(--global-color-blue);

    &:hover {
      background-color:
        hsl(
          var(--global-color-blue-h) var(--global-color-blue-s) calc(var(--global-color-blue-l) * 0.93)
        );
    }

    &:active {
      background-color:
        hsl(
          var(--global-color-blue-h) var(--global-color-blue-s) calc(var(--global-color-blue-l) * 0.85)
        );
    }
  }

  &.red:not(.disabled) {
    color: white;
    background-color: var(--global-color-red);
    &:hover { background-color: #D21B59; }
    &:active { background-color: #BD1951; }
  }

  &:disabled {
    cursor: auto;
    background-color: var(--global-color-gray-4);

    &:hover {
      background-color: var(--global-color-gray-4);
      cursor: not-allowed;
    }
  }

  .loadingSpinner {
    @include flex-center;
  }
}
