@import 'src/global/mixins.scss';
@import 'src/screens/platform/cross-platform-components/Navbar/components/navbarButtonStyle.module.scss';

$dropdown-min-width: 150px;
$dropdown-max-width: 240px;

.screenButton {
  @extend .navbarButton;

  .icon {
    min-width: 16px;
    width: 16px;
  }

  .label {
    @include flex-horizontal;
    flex-grow: 1;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    max-width: var(--text-max-width);
    overflow: hidden;
    transition:
      opacity var(--global-transition-duration) ease,
      max-width var(--global-transition-duration) ease;

    .spacedLabel {
      padding-left: var(--global-space-double);
    }
  }

  &.minimized {
    .label {
      opacity: 0;
      max-width: 0;
    }
  }

  &.selected {
    .icon {
      color: var(--global-color-blue);
    }

    .label {
      color: var(--global-color-blue);
    }
  }
}

.open {
  background-color: var(--global-color-gray-5);
}

.dropdown {
  @include flex-stretch;
  flex-direction: column;
  border-radius: var(--global-space-single);
  border: 1px solid var(--global-color-gray-7);
  min-width: $dropdown-min-width;
  max-width: $dropdown-max-width;
  overflow: visible;

  .dropdownOption {
    padding: var(--global-space-single) calc(var(--global-space-half) * 5);
    color: var(--global-color-dark-gray);
    text-align: left;
    font-size: 12px;
    font-weight: 500;

    &.selected {
      background-color: var(--global-color-gray-4);
    }

    &:hover {
      background-color: var(--global-color-gray-4);
    }
  }

  .separator {
    border-top: 1px solid var(--global-color-gray-4);
    margin: var(--global-space-single) 0;
  }

  .externalLink {
    padding: var(--global-space-single) calc(var(--global-space-half) * 5);
    color: var(--global-color-blue);

    &:hover {
      background-color: var(--global-color-gray-4);
    }
  }

  .internalLink {
    color: var(--global-color-dark-gray);
    text-align: left;
  }
}
