@import 'src/global/mixins.scss';
@import 'src/screens/platform/feed/FeedScreen/components/NotificationsCard/NotificationsTabs/AnnouncementsTab/AnnouncementCard/AnnouncementContent/style.module.scss';

.personWithImageContainer {
  @include flex-horizontal;
  gap: var(--global-space-half);

  &.border {
    @include rounded-large;
    border: 1px solid var(--global-color-gray-4);
    padding: var(--global-space-single) calc(3 * var(--global-space-half));
  }

  .image {
    @include flex-center;
  }

  .details {
    @include flex-start;
    flex-direction: column;
    gap: var(--global-space-half);
    white-space: nowrap;

    .name {
      font-weight: 400;
      color: var(--global-color-es-gray-dark);
      font-size: 12px;
    }

    .department {
      font-size: 10px;
      color: var(--global-color-es-gray-light);
    }
  }

  &.medium {
    gap: $announcement-content-gap;
  }

  &.oneLiner {
    .details {
      display: flex;
      flex-direction: row;
      gap: var(--global-space-single);

      .name {
        font-size: 14px;
      }

      .bullet {
        color: var(--global-color-gray-7);
      }

      .department {
        color: var(--global-color-es-gray-dark);
        align-self: center;
      }
    }
  }
}
