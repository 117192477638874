@import 'src/global/mixins.scss';

.metricsContainer {
  @include flex-horizontal;
  gap: var(--global-space-single);
  flex-wrap: wrap;
  align-items: flex-start;

  &.scrollable {
    height: 120px;
    overflow: auto;
    flex-wrap: nowrap;
  }
}
