.iconsContainer {
  width: 120px;
  display: flex;
  flex-wrap: wrap;
}

.additionalPrivateNode {
  margin-top: 4px;
}

.tooltip {
  height: fit-content;
  width: fit-content;
  min-width: 320px;
  padding: var(--global-space-double);
}
