@import 'src/global/mixins.scss';

.homeScreenContainer {
  @include flex-start;
  justify-content: center;
  min-height: 100%;
  background:
    radial-gradient(at 19% 31%, #B4E3FF 0, transparent 50%),
    radial-gradient(at 57.9% 82.8%, #AECBFC 0, transparent 50%),
    radial-gradient(at 67.3% 67.4%, #B4E3FF 0, transparent 50%),
    radial-gradient(at 53.4% 28.7%, #92B8F9 0, transparent 50%),
    radial-gradient(at 72.4% 46.8%, hsl(203deg 91% 36%) 0, transparent 50%),
    radial-gradient(at 94% 1%, hsl(218deg 100% 71%) 0, transparent 50%),
    radial-gradient(at 40.7% 56.4%, hsl(218deg 100% 71%) 0, transparent 50%);

  .content {
    width: 680px;
    display: flex;
    flex-direction: column;
    gap: var(--global-space-double);
    padding: calc(10 * var(--global-space-half)) 0;
  }
}
