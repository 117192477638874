@import 'src/global/mixins.scss';

.dropdownClassName {
  width: 680px;
  max-height: 500px;
  box-sizing: border-box;
  padding: 0;
  @include rounded-large;

  &.dropdownOpen {
    border-start-start-radius: 0;
    border-start-end-radius: 0;

    .lastSection {
      padding-bottom: calc(3 * var(--global-space-half));
    }
  }
}

.separator {
  width: 100%;
  height: 0;
  border-top: 1px solid var(--global-color-gray-7);
}
