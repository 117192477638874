@import 'src/global/mixins.scss';

.modalContent {
  @include rounded;
  background-color: white;
  border: 0;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: auto;
  outline: none;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-overflow-scrolling: touch;

  &.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--global-color-modal-overlay);
}
