@import 'src/global/mixins.scss';
@import 'src/common-ui-components/MultiImages/style.module.scss';

.tooltip {
  @include flex-horizontal;
  margin: var(--global-space-single);
  gap: var(--global-space-single);

  .tooltipImage {
    height: 48px;
    width: 48px;
    object-fit: contain;
    border: 2px solid var(--border-color);
    font-size: 24px;
    flex-shrink: 0;

    &.square {
      @include square-image;
    }

    &.round {
      @include round-image;
    }
  }

  .tooltipContent {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}
