@import 'src/global/mixins.scss';

.notFoundPageContainer {
  background-color: var(--global-color-gray-5);
  height: 100%;
  padding: var(--global-space-double);

  &.noPadding {
    padding: 0;
  }

  .card {
    @include flex-center(column);
    text-align: center;
    gap: var(--global-space-triple);

    .header {
      color: var(--global-color-light-blue);
      font-size: 150px;
      font-weight: 700;
      line-height: 1;
    }

    .content {
      @include container;

      .title {
        color: var(--global-color-dark-blue);
        font-size: 18px;
        font-weight: 700;
      }

      .subtitle {
        color: var(--global-color-dark-gray);
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }

    .actions {
      display: flex;
      gap: var(--global-space-single);
      height: var(--global-button-height);

      .contactUs {
        border: 1px solid var(--global-color-blue);
        color: var(--global-color-blue);
      }
    }
  }
}
