@import 'src/screens/platform/cross-platform-components/Navbar/components/BottomActions/SwitchTenantModal/style.module.scss';

.columnsFilterPopover {
  width: 600px;
  display: flex;
  flex-direction: column;

  &.loading {
    max-height: 150px;
  }

  .filterColumns {
    display: flex;
    flex-direction: row;
    gap: var(--global-space-double);
    overflow: hidden;

    .column {
      position: relative;
      flex-grow: 1;
      flex-basis: 0;
      padding-right: var(--global-space-single);
      @include visible-scrollbar;
      overflow: auto;
    }
  }
}
