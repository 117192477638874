@import 'src/global/mixins.scss';

.container {
  @include rounded-card;
  display: flex;
  flex-direction: column;

  .card {
    padding: var(--global-space-single) var(--global-space-double);
    display: flex;
    flex-direction: column;
    gap: var(--global-space-single);

    .type {
      font-size: 12px;
      font-weight: 500;
      color: var(--global-color-dark-gray);
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      color: var(--global-color-dark-blue);
    }

    .subTitle {
      font-size: 12px;
      color: var(--global-color-dark-gray);
    }
  }

  .footer {
    background-color: var(--global-color-gray-4);
    padding: var(--global-space-single) var(--global-space-double);
  }
}
