@import 'src/global/mixins.scss';

.masterFilters {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: var(--global-color-dark-blue);

  .filtersContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.disabledSubscriptionTooltip {
  width: 170px;
}
