@mixin shadow($position) {
  position: absolute;
  background: linear-gradient(to $position, rgb(255 255 255 / 0%), rgb(0 0 0 / 8%));
  #{$position}: 0;

  $shadow-size: 20px;
  @if $position == top or $position == bottom {
    left: 0;
    right: 0;
    height: $shadow-size;
  } @else {
    top: 0;
    bottom: 0;
    width: $shadow-size;
  }
}

.container {
  position: relative;

  .shadowTop {
    @include shadow(top);
  }

  .shadowBottom {
    @include shadow(bottom);
  }

  .shadowLeft {
    @include shadow(left);
  }

  .shadowRight {
    @include shadow(right);
  }

  .content {
    overflow: auto;
  }
}
