@import 'src/global/mixins.scss';

.eventsSummary {
  @include flex-horizontal;
  font-size: 12px;
  color: var(--global-color-dark-gray);

  .label {
    @include flex-center;
    height: var(--global-space-triple);
    white-space: nowrap;
  }
}
