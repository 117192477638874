@import 'src/global/mixins.scss';

.interactionsTable {
  line-height: normal;
  margin-bottom: var(--global-space-double);

  td {
    font-size: 12px;
    color: var(--global-color-dark-gray);
    vertical-align: top;
  }
}
