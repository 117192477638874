@import 'src/global/mixins.scss';

.personResult {
  @include flex-center;
  gap: var(--global-space-single);

  .details {
    display: flex;
    flex-direction: row;
    gap: var(--global-space-single);

    .name {
      font-size: 14px;
    }

    .bullet {
      color: var(--global-color-gray-7);
    }

    .department {
      color: var(--global-color-es-gray-dark);
      align-self: center;
    }
  }
}
