@import 'src/global/mixins.scss';

@mixin circle-icon($size, $padding-ratio) {
  min-width: $size;
  max-width: $size;
  height: $size;

  .image {
    width: $padding-ratio;
    height: $padding-ratio;
  }
}

.circleIcon {
  @include flex-center;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;

  &.border {
    border: 1px solid var(--global-color-gray-4);
    padding: var(--global-space-half);
  }

  .secondaryBadge {
    position: absolute;
    top: auto;
    right: 0;
    outline: 1px solid white;
    background-color: var(--global-color-gray-9);
    border-radius: 50%;
  }

  &.extraBig {
    @include circle-icon(60px, 90%);
  }

  &.big {
    @include circle-icon(40px, 90%);
  }

  &.medium {
    @include circle-icon(28px, 100%);
  }

  &.small {
    @include circle-icon(18px, 100%);

    .secondaryBadge {
      width: 10px;
    }
  }
}
