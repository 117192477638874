@import 'src/global/mixins.scss';

$card-min-width: 150px;
$card-height: 157px;

.interactionCardContainer {
  @include flex-start;
  justify-content: flex-start;
  min-width: $card-min-width;
  height: $card-height;
  gap: var(--global-space-double);
  flex: 1;
  position: relative;

  &.withBorder {
    @include rounded-card;
    padding: var(--global-space-double);
    box-shadow: none;
  }

  .sticker {
    position: absolute;
    top: -24px;
    right: -1px;
    background-color: var(--global-color-gray-4);
    will-change: transform;
    transition: transform 450ms;
    font-size: 11px;
    font-weight: 500;
    overflow: clip;

    &:hover {
      transition: transform 125ms;
      transform: translateY(-2px);
    }
  }

  .content {
    @include container;
    overflow: hidden;
  }
}
