@import 'src/global/mixins.scss';

.eventsList {
  display: flex;
  flex-direction: column;
  gap: var(--global-space-single);

  .date {
    font-size: 12px;
    color: var(--global-color-dark-gray);
    font-weight: 500;
  }
}
