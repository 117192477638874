@import 'src/global/mixins.scss';

.drawContainer {
  @include transition(transform);
  height: 100%;
  width: var(--global-right-panel-width);
  background-color: white;

  &.open {
    transform: translate(0%);
  }

  &.closed {
    transform: translate(100%);
  }
}
