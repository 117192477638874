@import 'src/global/mixins.scss';

.rightPanel {
  z-index: var(--global-z-index-drawer);
}

.container {
  @include transition(transform);
  @include container;
  background-color: white;
  padding: var(--global-space-triple);
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  width: var(--global-right-panel-width);
  box-sizing: border-box;
  transform: translateX(100%);

  &.open {
    transform: translateX(0%);

    .header {
      @include flex-center;
      justify-content: space-between;

      .title {
        color: var(--global-color-dark-blue);
        font-size: 18px;
        font-weight: 500;
      }

      .closeButton {
        @include rounded;
        @include flex-center;
        border: 1px solid var(--global-color-gray-4);
        padding: var(--global-space-single);
        color: var(--global-color-dark-gray);

        .closeIcon {
          width: 16px;
        }
      }
    }
  }
}
