@import 'src/global/mixins.scss';
@import 'src/screens/platform/cross-platform-components/Interactions/style.module.scss';

.extendedInteractionsTableHeader {
  @include flex-horizontal;
  gap: $interactions-table-header-gap;
}

.insideSwitch {
  @include flex-horizontal;
  gap: var(--global-space-half);
}
