@import 'src/global/mixins.scss';
@import 'src/screens/platform/contentScreens/AnalyticsScreen/widgets/chartStyle.module.scss';

.card {
  @include flex-horizontal;
  @include transition(background-color);
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid var(--global-color-gray-4);
  padding: var(--global-space-double);
  padding-top: var(--global-space-triple);
  align-items: start;
  flex-direction: column;
  width: 175px;
  min-width: 200px;
  max-height: 100%;
  height: 110px;
  font-size: 12px;
  gap: var(--global-space-single);

  &:hover {
    background-color: var(--global-color-hover-gray);
  }

  &:disabled {
    background-color: white;

    &:hover {
      cursor: default;
    }
  }

  .title {
    color: var(--global-color-dark-gray);
    text-align: start;
  }

  .value {
    color: var(--global-color-dark-blue);
    margin-bottom: var(--global-space-half);
    height: 15px;
    text-align: start;
  }

  .additionalInfo {
    @include flex-horizontal;
    gap: var(--global-space-half);
    color: var(--global-color-gray-2);
    font-size: 10px;
    height: 15px;
  }
}
