@import 'src/global/mixins.scss';

.atomSearchResult {
  @include flex-horizontal;
  @include transition(background-color);
  gap: calc(var(--global-space-half) * 3);
  overflow: hidden;
  width: 100%;
  padding: calc(3 * var(--global-space-half));
  background-color: white;

  &:not(:last-child) {
    border-bottom: 1px solid var(--global-color-gray-6);
  }

  .externalLinkIcon {
    @include transition(opacity);
    opacity: 0;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    color: var(--global-color-dark-gray);
    margin-left: auto;
  }

  &:hover {
    background-color: var(--global-color-light-blue);

    .externalLinkIcon {
      opacity: 1;
    }
  }

  &.transparent {
    background-color: transparent;

    &:hover {
      background-color: white;
    }
  }
}
