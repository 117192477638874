@import 'src/global/mixins.scss';

.container {
  @include container;

  .questions {
    display: flex;
    align-items: center;
    gap: var(--global-space-double);
    flex-wrap: wrap;
  }
}
