.changedFilterDot {
  margin-left: var(--global-space-single);
  width: var(--global-space-single);
  height: var(--global-space-single);
  border-radius: 50%;
  background-color: var(--global-color-blue);

  &.clickable {
    cursor: pointer;
  }
}
