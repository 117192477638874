@import 'src/global/mixins.scss';

.optionsPicker {
  height: var(--global-button-height);
  border: 1px solid var(--global-color-gray-4);
  @include rounded;
  @include flex-horizontal;
  overflow: hidden;

  button {
    font-size: 12px;
    color: var(--global-color-dark-gray);
    flex: 1;
    height: 100%;
    border-radius: 0;
    padding: 0 var(--global-space-double);
    margin: 0;

    &:hover {
      background-color: var(--global-color-gray-3);
    }

    &.selected {
      background-color: var(--global-color-dark-gray);
      color: white;

      &:hover {
        background-color: var(--global-color-gray-2);
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: var(--global-color-gray-3);

      &.selected,
      &.selected:hover {
        background-color: darken(#E9E9E9, 15);
      }

      &:hover {
        background-color: var(--global-color-gray-3);
      }
    }
  }
}
